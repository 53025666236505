<template>
  <div>
    <h2 class='text-lg font-semibold uppercase text-gray-800'>Filters</h2>
    <dashboard-view-editing-filters-create-rule @update:addrule='addRule' />
    <dashboard-view-editing-filters-existing-rule
      v-for='(rule, index) in filterExpressions'
      :key='`dashboard-filter-layer-1-rule-${index}-${firstRule}`'
      :rule='rule'
      :expressions-index='index'
      @update:removerule='removeRule' />
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import DashboardViewEditingFiltersCreateRule   from '@/views/dashboard/DashboardViewEditingFiltersCreateRule.vue'
import DashboardViewEditingFiltersExistingRule from '@/views/dashboard/DashboardViewEditingFiltersExistingRule.vue'
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'DashboardViewEditingFilters',
  components: {
    DashboardViewEditingFiltersCreateRule,
    DashboardViewEditingFiltersExistingRule
  },
  computed: {
    ...mapState('dashboardViews', [
      'currentDashboardView'
    ]),
    filterExpressions () {
      return this.currentDashboardView.filters.expressions
    },
    filterLogicalOperator () {
      return this.currentDashboardView.filters.logical_operator
    },
    firstRule () {
      return this.currentDashboardView.filters.expressions[0]
    },
  },
  methods: {
    ...mapMutations('dashboardViews', [
      'updateCurrentDashboardViewFilters'
    ]),
    addRule (newRule) {
      let newFilters = cloneDeep(this.currentDashboardView.filters)
      newFilters.expressions.push(newRule)
      this.updateCurrentDashboardViewFilters(newFilters)
    },
    removeRule (index) {
      let newFilters = cloneDeep(this.currentDashboardView.filters)
      newFilters.expressions.splice(index, 1)
      this.updateCurrentDashboardViewFilters(newFilters)
    },
  },
}
</script>
